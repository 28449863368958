<template>
  <v-container justify-center id="login" class="fill-height" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <v-icon>mdi-lock</v-icon>
              {{ $t("login-title") }}
            </div>
          </template>
          <v-card-text class="text-center">
            <div class="container-logo-small-pages">
              <img src="../assets/logo.png" alt="Ladysara ERP" />
            </div>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <v-form @submit="login()">
                <ValidationProvider
                  rules="required|email"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    color="secondary"
                    v-model="email"
                    prepend-icon="mdi-email"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    autocomplete
                    class="mb-8"
                    color="secondary"
                    v-model="password"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="password_view ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (password_view = !password_view)"
                    :type="password_view ? 'password' : 'text'"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>

                <v-btn
                  type="submit"
                  @click="login()"
                  v-if="!logging"
                  color="primary"
                  >{{ $t("login-button") }}</v-btn
                >
              </v-form>
            </ValidationObserver>
            <v-progress-circular
              v-if="logging"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>

            <div v-if="loginError">
              <div style="height:10px"></div>
              <v-alert type="error" dense dark dismissible>
                <span class="text-uppercase" v-text="loginError" />
              </v-alert>
            </div>
            <p style="margin-top:10px;">
              <router-link to="/recovery">{{
                $t("login-lost-password")
              }}</router-link>
            </p>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      logging: false,
      email: "",
      password: "",
      loginError: "",
      password_view: String,
    };
  },

  mounted() {
    this.logging = false;
    this.$refs.obs.reset();
  },

  computed: {
   
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  methods: {
   
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    login: function() {
      let email = this.email;
      let password = this.password;
      this.loginError = "";
      this.logging = true;
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          this.$store
            .dispatch("fetchStaticData")
            .then(() => {
              this.$router.push("/");
            })
            .catch((err) => {
              this.loginError = err;
              this.logging = false;
            });
        })
        .catch((err) => {
          this.loginError = err;
          this.logging = false;
        });
    },
  },
};
</script>
