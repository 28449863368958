<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    dark
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    mini-variant
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
    :src="background_image"
  >
    <v-divider class="mb-1" />

    <v-list dense nav>
      <base-item-group :item="profile()" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.items = [];
    if (this.$store.state.hasRight("19")) {
      this.items.push({
        icon: "mdi-view-dashboard",
        title: this.$t("sidebar-dashboard"),
        to: "/",
      });
    }

    if (this.$store.state.hasRight("29")) {
      this.items.push({
        icon: "mdi-format-list-text",
        title: this.$t("sidebar-planner"),
        to: "/planner",
      });
      this.items.push({
        icon: "mdi-format-list-text",
        title: this.$t("sidebar-planner-t"),
        to: "/planner-tab",
      });
    }

    if (this.$store.state.hasRight("29"))
      this.items.push({
        icon: "mdi-table",
        title: this.$t("sidebar-planner-shifts"),
        to: "/shifts",
      });

    if (this.$store.state.hasRight("33"))
      this.items.push({
        icon: "mdi-receipt-text-clock-outline",
        title: this.$t("sidebar-shifts-weeks"),
        to: "/weeks-shifts",
      });

    if (this.$store.state.hasRight("37"))
      this.items.push({
        icon: "mdi-view-agenda-outline",
        title: this.$t("sidebar-planner-templates"),
        to: "/planner-templates",
      });

    if (this.$store.state.hasRight("22"))
      this.items.push({
        icon: "mdi-account-tie",
        title: this.$t("sidebar-registry"),
        to: "/registry",
      });

    if (this.$store.state.hasRight("41"))
      this.items.push({
        icon: "mdi-account-reactivate-outline",
        title: this.$t("sidebar-registry-shifts"),
        to: "/registry-shifts",
      });

    if (this.$store.state.hasRight("45"))
      this.items.push({
        icon: "mdi-account-clock-outline",
        title: this.$t("sidebar-users-shifts"),
        to: "/users-shifts",
      });

    if (this.$store.state.hasRight("1"))
      this.items.push({
        icon: "mdi-account",
        title: this.$t("sidebar-manage-users"),
        to: "/permissions-users",
      });

    if (this.$store.state.hasRight("9"))
      this.items.push({
        icon: "mdi-account-group",
        title: this.$t("sidebar-manage-groups"),
        to: "/permissions-groups",
      });

    if (this.$store.state.isSuperAdmin())
      this.items.push({
        icon: "mdi-application",
        title: this.$t("sidebar-applications"),
        to: "/permissions-applications",
      });
  },

  data: () => ({
    items: [],
    background_image: require("../assets/bg-header.jpg"),
  }),

  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },

    computedItems() {
      return this.items.map(this.mapItem);
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },

    profile() {
      return {
        avatar: this.isLoggedIn() ? this.hasAvatar() : null,
        gravatar: this.isLoggedIn() ? this.hasGravatar() : null,
        avatarimg: this.isLoggedIn() ? this.getGravatar() : null,
        avatarletters: this.isLoggedIn() ? this.getProfileLetters() : null,
        group: "",
        title: this.$t("sidebar-profile"),
        children: [
          {
            to: "/profile",
            title: this.$t("sidebar-profile-settings"),
          },
        ],
      };
    },

    isLoggedIn: function () {
      return this.$store.state.user;
    },

    hasAvatar: function () {
      return this.$store.state.user.avatar ? true : false;
    },

    hasGravatar: function () {
      return this.$store.state.user.gravatar ? true : false;
    },

    getGravatar: function () {
      return "data:image/jpeg;base64," + this.$store.state.user.avatar;
    },

    getUsername: function () {
      return this.$store.state.user.first_name;
    },

    getProfileLetters: function () {
      let user = this.$store.state.user;
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

.theme--dark.v-sheet
  background-color: #363636

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
