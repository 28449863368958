export default {
  light: {
    primary: '#00a3bf',
    secondary: '#72c491',
    tertiary: '#72c491',
    accent: '#72c491',
    error: '#83649a',
    info: '#e9b4e9',
    success: '#72c491',
    warning: '#ffa21a'
  }

}
