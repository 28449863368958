import Vue from 'vue'
import Vuetify from 'vuetify'
import theme from './theme'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  iconfont: 'mdi',
  theme : {
    themes : theme
  }
})

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: theme.light
    },
  },
})

export default vuetify