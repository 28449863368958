import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import Login from "../views/Login";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SideBar from "../components/Sidebar";

const UsersManager = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/UsersManager.vue");
const GroupsManager = () =>
  import(/* webpackChunkName: "admin-bundle" */ "../views/GroupsManager.vue");
const ApplicationsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/ApplicationsManager.vue"
  );

const PlannerTemplatesManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/PlannerTemplatesManager.vue"
  );

const RegistryShiftsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/RegistyShiftsManager.vue"
  );

const UsersShiftsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/UsersShiftsManager.vue"
  );

const WeeksShiftsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "../views/WeeksShiftsManager.vue"
  );

const Logs = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Logs.vue");

const Dashboard = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Dashboard.vue");
const Recovery = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Recovery.vue");
const Registry = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Registry.vue");
const Planner = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Planner.vue");
const PlannerShifts = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/PlannerShifts.vue");
const PlannerTab = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/PlannerTab.vue");

const Reset = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Reset.vue");
const Profile = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Profile.vue");
const FileManager = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/FileManager.vue");

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      components: {
        main: Login,
      },
    },
    {
      path: "/recovery",
      name: "recovery",
      components: {
        main: Recovery,
      },
    },
    {
      path: "/reset",
      name: "reset",
      components: {
        main: Reset,
      },
    },

    {
      path: "/",
      name: "dashboard",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Dashboard,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/registry",
      name: "registry",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Registry,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "22",
      },
    },

    {
      path: "/planner",
      name: "planner",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Planner,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "29",
      },
    },
    {
      path: "/planner-tab",
      name: "planner-tab",
      components: {
        header: Header,
        sideBar: SideBar,
        main: PlannerTab,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "29",
      },
    },
    {
      path: "/shifts",
      name: "plannerview",
      components: {
        header: Header,
        sideBar: SideBar,
        main: PlannerShifts,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "29",
      },
    },

    {
      path: "/weeks-shifts",
      name: "weeks-shifts",
      components: {
        header: Header,
        sideBar: SideBar,
        main: WeeksShiftsManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "33",
      },
    },

    {
      path: "/planner-templates",
      name: "planner-templates",
      components: {
        header: Header,
        sideBar: SideBar,
        main: PlannerTemplatesManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "37",
      },
    },

    {
      path: "/registry-shifts",
      name: "registry-shifts",
      components: {
        header: Header,
        sideBar: SideBar,
        main: RegistryShiftsManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "41",
      },
    },

    {
      path: "/users-shifts",
      name: "users-shifts",
      components: {
        header: Header,
        sideBar: SideBar,
        main: UsersShiftsManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "45",
      },
    },

    {
      path: "/permissions-users",
      name: "users",
      components: {
        header: Header,
        sideBar: SideBar,
        main: UsersManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "1",
      },
    },
    {
      path: "/permissions-groups",
      name: "groups",
      components: {
        header: Header,
        sideBar: SideBar,
        main: GroupsManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresRight: "9",
      },
    },
    {
      path: "/permissions-applications",
      name: "applications",
      components: {
        header: Header,
        sideBar: SideBar,
        main: ApplicationsManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
      },
    },

    {
      path: "/profile",
      name: "profile",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Profile,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/filemanager",
      name: "filemanager",
      components: {
        header: Header,
        sideBar: SideBar,
        main: FileManager,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/logs",
      name: "logs",
      components: {
        header: Header,
        sideBar: SideBar,
        main: Logs,
        footer: Footer,
      },
      meta: {
        requiresAuth: true,
        requiresSuperAdmin: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.meta.requiresRight && !store.state.hasRight(to.meta.requiresRight)) {
      console.log(to.meta.requiresRight);
      next("/login");
      return;
    }

    if (to.meta.requiresAdmin && !store.state.isAdmin()) {
      next("/login");
      return;
    }

    if (to.meta.requiresSuperAdmin && !store.state.isSuperAdmin()) {
      next("/login");
      return;
    }

    if (store.state.user.id > 0) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
